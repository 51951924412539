import { Container } from '@uc-common/container';
import styled, { css } from 'styled-components';
import { Link } from '../../../Link';
import { Paragraph } from '../../styles';
import { SectionHeading } from '../../../Typography';

export const AdvantagesScreenSection = styled.article(
  ({ theme: { tablet, desktop } }) => css`
    margin-top: 90px;
    display: flex;
    flex-direction: column;
    align-items: center;

    ${tablet()} {
      margin-top: 100px;
    }

    ${desktop()} {
      margin-top: 120px;
    }
  `
);

export const AdvantagesWrapper = styled(Container)(
  ({ theme: { tablet, laptop, desktop } }) => css`
    display: grid;
    grid-gap: 40px;
    grid-template-columns: repeat(1, 280px);
    justify-content: center;
    margin-top: 75px;

    ${tablet()} {
      grid-template-columns: repeat(2, 280px);
      justify-content: space-between;
    }

    ${laptop()} {
      grid-template-columns: repeat(3, 280px);
      grid-gap: 50px;
    }

    ${desktop()} {
      grid-template-columns: repeat(3, 324px);
      grid-gap: 66px;
    }
  `
);

export const AdvantageBlock = styled.div(
  ({ theme: { laptop } }) => css`
    display: flex;
    align-items: center;
    flex-direction: column;

    ${laptop()} {
      align-items: flex-start;
    }
  `
);

export const AdvantageIcon = styled.div(
  ({ $iconSrc }) => css`
    width: 30px;
    height: 30px;
    margin-bottom: 15px;
    background: url(${$iconSrc}) no-repeat center;
  `
);

export const AdvantageHeading = styled.h3`
  font-size: 18px;
  line-height: 1.2;
  color: #000000;
  text-align: center;
`;

export const AdvantageParagraph = styled(Paragraph)(
  ({ theme: { laptop } }) => css`
    ${laptop()} {
      text-align: left;
      font-size: 16px;
    }
  `
);

export const AdvantageLink = styled(Link)`
  color: #596570;
  text-decoration: none;
  border-bottom: 1px solid;

  &:hover {
    color: #3e4750;
  }
`;

export const AdvantageScreenHeading = styled(SectionHeading)(
  ({ theme: { laptop } }) => css`
    ${laptop()} {
      max-width: 840px;
    }
  `
);

export const AdvantageScreenButtonsContainer = styled.div(
  ({ theme: { tablet } }) => css`
    display: grid;
    grid-template-columns: 198px;
    grid-gap: 10px;
    margin-top: 30px;

    ${tablet()} {
      grid-template-columns: 198px 198px;
    }
  `
);
