import React from 'react';

import {
  SupportedFrameworksWrapper,
  SupportedFrameworksTitle,
  SupportedFrameworksContainer,
} from '../../styles/MainScreen.styles';
import { SupportedFramework } from './SupportedFramework';
import { useSupportedFrameworks } from '../../../supported-frameworks-provider';
import { SUPPORTED_FRAMEWORKS } from './data';

export const SupportedFrameworks = () => {
  const { framework, setFramework } = useSupportedFrameworks();

  return (
    <SupportedFrameworksWrapper>
      <SupportedFrameworksTitle>Select a framework</SupportedFrameworksTitle>
      <SupportedFrameworksContainer>
        {SUPPORTED_FRAMEWORKS?.map((item) => (
          <SupportedFramework
            key={item.framework}
            selected={framework === item.framework}
            onClick={() => setFramework(item.framework)}
            icon={item.icon}
          />
        ))}
      </SupportedFrameworksContainer>
    </SupportedFrameworksWrapper>
  );
};
