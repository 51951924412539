import styled, { css } from 'styled-components';
import { Button } from '@uc-common/button';
import { Container } from '@uc-common/container';
import { ReactComponent as LinkIconSvg } from '../assets/link.svg';
import { Link } from '../../../Link';

export const MainScreenSection = styled.article(
  ({ theme: { tablet, desktop } }) => css`
    padding-top: 60px;
    background-color: #000000;

    ${tablet()} {
      padding-top: 80px;
    }

    ${desktop()} {
      padding-top: 90px;
    }
  `
);

export const MainScreenContainer = styled(Container)`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
`;

export const MainScreenHeadingContainer = styled.div(
  ({ theme: { laptop, desktop } }) => css`
    display: flex;
    align-items: center;
    flex-direction: column;
    margin: 0 0 50px 0;

    ${laptop()} {
      align-items: center;
      flex-basis: 320px;
    }

    ${desktop()} {
      flex-basis: 380px;
      margin: 0 0 60px 0;
    }
  `
);

export const MainScreenHeading = styled.h1(
  ({ theme: { tablet, laptop, desktop } }) => css`
    font-size: 30px;
    line-height: 1.2;
    font-weight: 700;
    color: #ffffff;
    display: block;

    ${tablet()} {
      font-size: 56px;
    }

    ${laptop()} {
      font-size: 60px;
    }

    ${desktop()} {
      font-size: 70px;
      max-width: 860px;
    }
  `
);

export const MainScreenSubtext = styled.p(
  ({ theme: { tablet, laptop, desktop } }) => css`
    font-weight: 500;
    font-size: 16px;
    line-height: 160%;
    text-align: center;
    color: #bbc4d2;
    margin-left: auto;
    margin-right: auto;
    max-width: 660px;
    margin-top: 19px;

    ${tablet()} {
      font-size: 18px;
      margin-top: 30px;
    }

    ${laptop()} {
      font-size: 20px;
    }

    ${desktop()} {
      font-size: 22px;
      max-width: 860px;
    }
  `
);

export const MainScreenButtonsContainer = styled.div(
  ({ theme: { tablet } }) => css`
    display: grid;
    grid-template-columns: 198px;
    grid-gap: 10px;
    margin-top: 30px;

    ${tablet()} {
      grid-template-columns: 198px 198px;
      margin-top: 45px;
    }
  `
);

export const MainScreenBetaUploader = styled.div`
  justify-content: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
`;

export const MainScreenUndertext = styled.div(
  ({ theme: { tablet } }) => css`
    color: #bbc4d2;
    max-width: 535px;
    font-weight: 500;
    font-size: 18px;
    line-height: 160%;
    margin: 30px 0px;

    ${tablet()} {
      margin: 40px;
    }
  `
);

export const MainScreenGradientTitle = styled.span`
  background: radial-gradient(83.93% 96.65% at 117.86% 50%, #ffc700 0%, #ffedab 100%);
  /* stylelint-disable-next-line */
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
`;

export const MainScreenLinkIcon = styled(LinkIconSvg)`
  margin-left: 4px;
`;

export const MainScreenUndertextLink = styled(Link)`
  color: #4e7ff7;
  text-decoration: none;
  display: inline-flex;
  align-items: center;

  &:hover,
  &:focus {
    color: #0242df;
    text-decoration: underline;
  }

  &:visited {
    color: #3771ff;

    &:hover,
    &:focus {
      color: #0242df;
    }

    &:active {
      color: #3771ff;
    }
  }
`;

export const SupportedFrameworksWrapper = styled.div(
  ({ theme: { tablet } }) => css`
    text-align: center;
    margin-top: 30px;
    width: 100%;

    ${tablet()} {
      margin-top: 45px;
    }
  `
);

export const SupportedFrameworksTitle = styled.h5(
  ({ theme: { tablet, laptop } }) => css`
    font-weight: 500;
    font-size: 10px;
    line-height: 2.4;
    text-align: center;
    letter-spacing: 0.1em;
    text-transform: uppercase;
    color: #838f9a;

    ${tablet()} {
      font-size: 12px;
      line-height: 24px;
    }

    ${laptop()} {
      font-size: 14px;
    }
  `
);

export const SupportedFrameworksContainer = styled.form`
  display: flex;
  flex-direction: row;
  justify-content: center;
  flex-wrap: wrap;
  list-style: none;
  width: 100%;
  align-items: center;
  gap: 35px 25px;
  margin-top: 20px;
`;

export const SupportedFrameworksItem = styled(Button)(
  ({ $isSelected = false }) => css`
    height: 65px;
    padding: 15px;
    width: 150px;
    border: 1px solid;
    border-color: rgba(255, 255, 255, 0%);
    border-radius: 8px;
    cursor: pointer;
    background-color: transparent;
    color: #838f9a;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 0px 0px 12px 1px rgba(255, 199, 0, 0%);
    transition:
      color 0.2s ease-in-out,
      border-color 0.3s ease-in-out,
      background-color 0.1s ease-in,
      box-shadow 0.2s ease-in;

    &:hover {
      border-color: rgba(255, 199, 0, 70%);
      color: #ffe486;
      box-shadow: 0px 0px 12px 1px rgba(255, 199, 0, 50%);
    }

    &:active {
      background-color: rgba(255, 199, 0, 15%);
    }

    ${$isSelected &&
    css`
      border-color: rgba(255, 199, 0, 70%);
      color: #ffe486;
      box-shadow: 0px 0px 12px 1px rgba(255, 199, 0, 50%);
    `}
  `
);
