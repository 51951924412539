import React from 'react';
import styled, { css } from 'styled-components';

const RadioButtonLabel = styled.label(
  ({ $color }) => css`
    display: flex;
    flex-direction: column;
    align-items: center;
    color: #656d76;
    font-size: 14px;
    font-weight: 500;
    line-height: 1.6em;
    justify-content: space-between;
    cursor: pointer;

    --icon-selector-color: #000;
    --icon-color: ${$color};

    :where(&:hover, &:focus) {
      --icon-selector-color: #656d76;
    }

    :where(&:hover, &:focus) > svg {
      color: #ffffff;
    }
  `
);

const RadioButtonInput = styled.input`
  &:checked + label {
    color: #ffffff;
    --icon-selector-color: #ffffff;
  }
`;

export const RadioButton = ({ name, value, label, icon, onChange, checked, $color }) => {
  const id = `radio-${name}-${value}`;
  return (
    <>
      <RadioButtonInput
        hidden
        type="radio"
        id={id}
        name={name}
        value={value}
        checked={checked}
        onChange={onChange}
      />
      <RadioButtonLabel htmlFor={id} $color={$color}>
        {icon}
        <span>{label}</span>
      </RadioButtonLabel>
    </>
  );
};
