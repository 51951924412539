import { Button, ButtonTypeEnum } from '@uc-common/button';
import React from 'react';

import { Link } from '../../Link';
import { BetaUploaderDemo } from './components/BetaUploaderDemo';
import { SupportedFrameworks } from './components/SupportedFrameworks/SupportedFrameworks';

import {
  MainScreenContainer,
  MainScreenButtonsContainer,
  MainScreenHeading,
  MainScreenHeadingContainer,
  MainScreenSection,
  MainScreenSubtext,
  MainScreenBetaUploader,
  MainScreenUndertext,
  MainScreenGradientTitle,
  MainScreenLinkIcon,
  MainScreenUndertextLink,
} from './styles/MainScreen.styles';

export const MainScreen = () => {
  return (
    <MainScreenSection data-navbar-theme="dark">
      <MainScreenContainer>
        <MainScreenHeadingContainer>
          <MainScreenHeading>
            File uploader that <MainScreenGradientTitle>cares </MainScreenGradientTitle>
            for your users
          </MainScreenHeading>
          <MainScreenSubtext>
            Open source, infinitely customizable file uploading widget powered by the robust global
            infrastructure.
          </MainScreenSubtext>
          <SupportedFrameworks />
          <MainScreenButtonsContainer>
            <Button
              as={Link}
              to="/accounts/signup/"
              type={ButtonTypeEnum.PRIMARY}
              data-analytics="btn_signup_0"
            >
              Start now for free
            </Button>
            <Button
              as={Link}
              to="/schedule-demo/"
              type={ButtonTypeEnum.TRANSPARENT}
              data-analytics="btn_schedule-demo_0"
              ghost
            >
              Get a demo
            </Button>
          </MainScreenButtonsContainer>
        </MainScreenHeadingContainer>
        <MainScreenBetaUploader>
          <BetaUploaderDemo />
          <MainScreenUndertext>
            Simply choose mode and accent color and you’re ready to go. Or dive deeper into
            unlimited CSS styling.{' '}
            <MainScreenUndertextLink to="https://uploadcare.com/docs/file-uploader/">
              Documentation
              <MainScreenLinkIcon />
            </MainScreenUndertextLink>
          </MainScreenUndertext>
        </MainScreenBetaUploader>
      </MainScreenContainer>
    </MainScreenSection>
  );
};
