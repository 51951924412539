import styled, { css } from 'styled-components';
import { Code } from '@uc-common/code';

export const Container = styled.div(
  ({ theme: { tablet, laptop, desktop } }) => css`
    width: 100%;
    border: 1px solid #21262f;
    border-radius: 16px;
    color: #ffffff;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
    padding: 15px 10px 30px 10px;

    ${tablet()} {
      padding: 36px;
    }

    ${laptop()} {
      padding: 40px;
    }

    ${desktop()} {
      padding: 50px;
    }
  `
);

export const RowWrapper = styled.div(
  ({ theme: { tablet } }) => css`
    display: flex;
    flex-direction: column;
    width: 100%;
    z-index: 2;

    ${tablet()} {
      flex-direction: row;
    }
  `
);

export const StyledCode = styled(Code)(
  ({ theme: { tablet, laptop, desktop } }) => css`
    overflow: hidden;
    text-align: left;
    padding: 0px 5px 25px 5px;

    ${tablet()} {
      flex: 1;
      padding: 0px 12px 0px 0px;
    }

    ${laptop()} {
      padding: 0px 28px 0px 0px;
    }

    ${desktop()} {
      padding: 0px 36px 0px 0px;
    }

    code {
      padding: 0;
      background: #000;
      border: none;
      font-size: 13px;
      line-height: 22px;
    }

    pre {
      white-space: pre;
    }

    && {
      .operator {
        color: #49535d;
      }

      .property {
        color: #b392f0;
      }

      .string {
        color: #69e3bf;
      }
    }
  `
);

export const UploaderSvgWrapper = styled.div(
  ({ theme: { tablet } }) => css`
    display: flex;
    font-family: var(--font-sans);
    font-size: 14px;
    justify-content: center;
    align-items: center;

    ${tablet()} {
      flex: 1;
    }

    svg {
      width: 100%;
      height: 100%;
      overflow: hidden;
      pointer-events: none;
    }

    svg * {
      transition: all 0.3s ease-out;
    }
  `
);

export const Toolbar = styled.div(
  ({ theme: { tablet, desktop } }) => css`
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: max-content max-content;
    border-top: none;
    width: 100%;
    z-index: 2;
    grid-gap: 32px 0;
    padding: 40px 12px 0 12px;

    & :nth-child(3) {
      grid-column: 1/3;
    }

    ${tablet()} {
      grid-template-columns: 1fr 2fr 1fr;
      grid-template-rows: 1fr;
      border-top: 1px solid #21262f;
      margin-top: 36px;

      & :nth-child(3) {
        grid-row: 1;
        grid-column: 2;
      }
    }

    ${desktop()} {
      padding: 50px 12px 0px 12px;
    }
  `
);

export const ToolbarGroup = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  gap: 40px;
`;

export const ColorsWrapper = styled.div`
  display: grid;
  width: 100%;
  justify-content: space-evenly;
  grid-auto-flow: column;
  font-weight: 500;
  font-size: 14px;
  line-height: 1.6em;
  grid-template-rows: 1fr min-content;

  & :last-child {
    grid-row: 2;
    grid-column: 1/7;
    color: #656d76;
    margin-top: 8px;
  }
`;

export const Backdrop = styled.div(
  ({ $color, theme: { tablet } }) => css`
    position: absolute;
    background-color: ${$color || 'rgba(179, 55, 255, 0.3)'};
    filter: blur(50px);
    transform: translate3d(0, 0, 0);
    z-index: 0;
    border-radius: 16px;
    width: calc(100% + 30px);
    height: calc(100% - 40px);
    transition: background-color 0.3s;

    ${tablet()} {
      width: calc(100% + 40px);
      height: calc(100% - 66px);
    }
  `
);

export const Background = styled.div`
  background-color: #000;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0px;
  left: 0px;
  z-index: 1;
  border-radius: 16px;
`;
