import { Button, ButtonTypeEnum } from '@uc-common/button';
import React from 'react';

import { Link } from '../../Link/Link';
import { SplitCardSuperHeading } from '../../SplitCard';
import { SectionWrapper } from '../styles';
import { ReactComponent as AngularSvg } from './assets/angular.svg';
import { ReactComponent as ReactSvg } from './assets/react.svg';
import { ReactComponent as VueSvg } from './assets/vue.svg';
import {
  DeveloperScreenSplitCard,
  DevelopersScreenButtonWrapper,
  DevelopersScreenFeatureBlock,
  DevelopersScreenFeatureHeading,
  DevelopersScreenFeatureParagraph,
  DevelopersScreenFeaturesBlock,
  DevelopersScreenHeading,
  DevelopersScreenIcons,
  DevelopersScreenInfo,
  DevelopersScreenParagraph,
  DevelopersScreenSection,
  DevelopersScreenContent,
  DevelopersScreenDescription,
} from './styles/DevelopersScreen.styles';
import { PackagesCodeAccordeon } from './components/PackagesCodeAccordeon';

export const DevelopersScreen = () => (
  <DevelopersScreenSection>
    <SectionWrapper>
      <DeveloperScreenSplitCard>
        <DevelopersScreenContent>
          <SplitCardSuperHeading>Integration</SplitCardSuperHeading>
          <DevelopersScreenHeading>File Uploader designed for developers</DevelopersScreenHeading>
          <DevelopersScreenDescription>
            Install uploader using one of the following packages:
          </DevelopersScreenDescription>
          <PackagesCodeAccordeon />
        </DevelopersScreenContent>

        <DevelopersScreenInfo>
          <DevelopersScreenParagraph>
            Seamless integration flow for all major web development stacks. Type safety with a JSDoc
            declaration and TypeScript static analysis. Build custom uploading flow using Upload API
            directly or with an API client. Use native mobile libraries for iOS and Android to
            upload files.
          </DevelopersScreenParagraph>
          <DevelopersScreenButtonWrapper>
            <Button
              as={Link}
              target="_blank"
              to="https://github.com/uploadcare/blocks-examples/"
              rel="noopener noreferrer"
              type={ButtonTypeEnum.PRIMARY}
              data-analytics="btn_developers-screen_block-examples"
            >
              See examples -&#62;
            </Button>
            <DevelopersScreenIcons>
              <ReactSvg />
              <AngularSvg />
              <VueSvg />
            </DevelopersScreenIcons>
          </DevelopersScreenButtonWrapper>
        </DevelopersScreenInfo>

        <DevelopersScreenFeaturesBlock>
          <DevelopersScreenFeatureBlock
            to="https://uploadcare.com/docs/integrations/#mobile"
            target="_blank"
            data-analytics="btn_developers-screen_mobile-sdk"
          >
            <DevelopersScreenFeatureHeading>Mobile SDKs</DevelopersScreenFeatureHeading>
            <DevelopersScreenFeatureParagraph>
              Use native mobile libraries for iOS and Android to upload files.
            </DevelopersScreenFeatureParagraph>
          </DevelopersScreenFeatureBlock>

          <DevelopersScreenFeatureBlock
            to="https://uploadcare.com/api-refs/upload-api/"
            target="_blank"
            data-analytics="btn_developers-screen_upload-api"
          >
            <DevelopersScreenFeatureHeading>Upload API</DevelopersScreenFeatureHeading>
            <DevelopersScreenFeatureParagraph>
              Upload files RESTfully with Upload API.
            </DevelopersScreenFeatureParagraph>
          </DevelopersScreenFeatureBlock>

          <DevelopersScreenFeatureBlock
            to="https://uploadcare.com/products/file-uploader/js/"
            target="_blank"
            data-analytics="btn_developers-screen_javascript-api"
          >
            <DevelopersScreenFeatureHeading>JavaScript API client</DevelopersScreenFeatureHeading>
            <DevelopersScreenFeatureParagraph>
              Build custom uploading flow using API client for JS.
            </DevelopersScreenFeatureParagraph>
          </DevelopersScreenFeatureBlock>
        </DevelopersScreenFeaturesBlock>
      </DeveloperScreenSplitCard>
    </SectionWrapper>
  </DevelopersScreenSection>
);
