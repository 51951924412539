import React from 'react';

import { SectionHeading, SectionInfo, SectionParagraph } from '../styles';
import {
  ImageEditorImageContainer,
  ImageEditorOperation,
  ImageEditorOperations,
  ImageEditorSection,
  ImageEditorWrapper,
  ImageEditorImage,
} from './styles/ImageEditorScreen.styles';

export const ImageEditorScreen = () => (
  <ImageEditorSection>
    <ImageEditorWrapper>
      <SectionInfo>
        <SectionHeading>Built-in image editor</SectionHeading>
        <SectionParagraph>
          Raise the quality of user-generated content by providing 9 creative tools.
        </SectionParagraph>
        <ImageEditorOperations>
          {[
            'Crop',
            'Rotate',
            'Mirror',
            'Flip',
            'Enhance',
            'Sharpen',
            'Blur',
            'Grayscale',
            'Invert',
          ].map((operation) => (
            <ImageEditorOperation key={operation}>{operation}</ImageEditorOperation>
          ))}
        </ImageEditorOperations>
      </SectionInfo>
      <ImageEditorImageContainer>
        <ImageEditorImage
          src="https://ucarecdn.com/892b123f-7576-45fa-b3a3-9f75e8add03d/imageeditor.png"
          alt="Widget image editor CROP ROTATE MIRROR FLIP ENHANCE SHARPEN BLUR GRAYSCALE INVERT"
        />
      </ImageEditorImageContainer>
    </ImageEditorWrapper>
  </ImageEditorSection>
);
