import styled from 'styled-components';

export const IntegrationMethodToggleInput = styled.input`
  position: absolute;
  opacity: 0;
  width: 0;
  height: 0;
`;

export const IntegrationMethodToggleLabel = styled.label`
  background: #15212e;
  cursor: pointer;
  border-radius: 8px;
  padding: 12px 20px;
  display: flex;
  flex-direction: column;
  color: #bbc4d2;
  transition: all 0.3s;

  & span:first-child {
    font-weight: 500;
    font-size: 14px;
    line-height: 22px;
  }

  & span:last-child {
    font-size: 13px;
    line-height: 21px;
  }

  &:hover {
    color: #3771ff;
    background-color: #131920;
  }

  ${/* sc-selector */ IntegrationMethodToggleInput}:checked + & {
    color: #3771ff;
    box-shadow: 0px 0px 0px 2px rgba(55, 113, 255, 0.28);
    background-color: #131920;
  }
`;

export const IntegrationMethodToggleContainer = styled.label`
  display: grid;
  grid-gap: 8px;
  width: 100%;
  grid-auto-flow: column;
  grid-auto-columns: 1fr;
  margin-bottom: 25px;
`;
