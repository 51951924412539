import { graphql, useStaticQuery } from 'gatsby';

export const useBlocksVersion = () => {
  const {
    blocksVersion: { version },
  } = useStaticQuery(graphql`
    query {
      blocksVersion {
        version
      }
    }
  `);

  return version;
};
