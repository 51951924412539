import React from 'react';

import { ClientsSection, StyledClients } from './styles/ClientsScreen.styles';

export const ClientsScreen = () => {
  return (
    <ClientsSection data-navbar-theme="dark">
      <StyledClients />
    </ClientsSection>
  );
};
