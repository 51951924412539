import React from 'react';

import ApiIcon from '../assets/circle-api.svg';
import ChooseIcon from '../assets/circle-choose.svg';
import {
  SectionHeading,
  SectionInfo,
  SectionList,
  SectionListItem,
  SectionParagraph,
} from '../styles';
import {
  ExternalSourcesImageContainer,
  ExternalSourcesSection,
  ExternalSourcesWrapper,
  ExternalSourcesImage,
} from './styles/ExternalSources.styles';

export const ExternalSourcesScreen = () => (
  <ExternalSourcesSection>
    <ExternalSourcesWrapper>
      <SectionInfo>
        <SectionHeading>12 external sources</SectionHeading>
        <SectionParagraph>
          Let your users quickly pick their Instagram photos or Google Drive documents for file
          uploads.
        </SectionParagraph>
        <SectionList>
          <SectionListItem $iconSrc={ChooseIcon}>Choose as many as you need</SectionListItem>
          <SectionListItem $iconSrc={ApiIcon}>API for building custom sources</SectionListItem>
        </SectionList>
      </SectionInfo>
      <ExternalSourcesImageContainer>
        <ExternalSourcesImage
          src="https://ucarecdn.com/d36e6738-9a22-4658-bb77-831e4a87bece/sources.png"
          alt="Mobile uploads 100X more reliable. Filelink instantly available. Widget upload sources"
        />
      </ExternalSourcesImageContainer>
    </ExternalSourcesWrapper>
  </ExternalSourcesSection>
);
