import styled from 'styled-components';
import { Clients } from '~/src/components/Clients';

export const StyledClients = styled(Clients)`
  margin-top: 0;
  padding-top: 40px;

  & p {
    font-size: 14px;
  }

  & p + div {
    justify-content: space-around;
  }
`;

export const ClientsSection = styled.article`
  padding-bottom: 80px;
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.85) 0%, #000000 100%),
    linear-gradient(
      270deg,
      #00b4ff 0%,
      #b44aff 42.57%,
      #ff5fe8 67.05%,
      #ff5d9d 82.67%,
      #ff7356 100%
    );
`;
