import { Code } from '@uc-common/code';
import styled from 'styled-components';
import { LinkWithArrow } from '~/src/components/Typography';
import { Link } from '~/src/components/Link';

export const IntegrationContainer = styled.div`
  width: 100%;
  height: 100%;
  padding: 20px 76px 76px 76px;
  overflow-y: auto;
  position: relative;
`;

export const IntegrationTitle = styled.h2`
  font-weight: 700;
  font-size: 16px;
  line-height: 19px;
  margin-bottom: 20px;
  margin-top: 50px;
  color: #ffffff;

  &:first-child {
    margin-top: 30px;
  }
`;

export const IntegrationParagraph = styled.p`
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  color: #bbc4d2;
  margin-bottom: 8px;

  code {
    background: #2d4052;
    border-radius: 6px;
    padding: 2px 4px;
  }
`;

export const IntegrationCode = styled(Code)`
  code {
    --default-color: #bbc4d2;
    background: #131920;
    border: 1px solid #131920;
    border-radius: 8px;
    margin-bottom: 25px;
  }

  pre {
    white-space: pre;
  }
`;

export const IntegrationList = styled.ul`
  list-style-type: disc;
  margin-left: 1em;
  margin-bottom: 14px;
`;

export const IntegrationListItem = styled.li`
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  color: #bbc4d2;
`;

export const IntegrationLinkLinkWithArrow = styled(LinkWithArrow)`
  display: block;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  margin: 5px 0px;
`;

export const IntegrationLink = styled(Link)`
  display: inline-block;
  color: #bbc4d2;

  &:hover {
    color: #ffffff;
  }
`;
