import React from 'react';
import { FrameworksEnum } from '../../../supported-frameworks-provider';
import { ReactComponent as ReactLogo } from './assets/react-logo.svg';
import { ReactComponent as JsLogo } from './assets/javascript-logo.svg';
import { ReactComponent as VueJsLogo } from './assets/vue-js-logo.svg';
import { ReactComponent as AngularLogo } from './assets/angular-logo.svg';
import { ReactComponent as SvelteLogo } from './assets/svelte-logo.svg';

export const SUPPORTED_FRAMEWORKS = [
  {
    framework: `${FrameworksEnum.JAVASCRIPT}`,
    icon: <JsLogo />,
  },
  {
    framework: `${FrameworksEnum.REACT}`,
    icon: <ReactLogo />,
  },
  {
    framework: `${FrameworksEnum.SVELTE}`,
    icon: <SvelteLogo />,
  },
  {
    framework: `${FrameworksEnum.VUE}`,
    icon: <VueJsLogo />,
  },
  {
    framework: `${FrameworksEnum.ANGULAR}`,
    icon: <AngularLogo />,
  },
];
