import styled, { css } from 'styled-components';
import { Container } from '@uc-common/container';

export const SectionWrapper = styled(Container)(
  ({ theme: { laptop } }) => css`
    display: flex;
    flex-direction: column;
    align-items: center;

    ${laptop()} {
      flex-direction: row;
      justify-content: space-between;
    }
  `
);

export const SectionInfo = styled.div(
  ({ theme: { laptop, desktop } }) => css`
    display: flex;
    align-items: center;
    flex-direction: column;
    max-width: 400px;

    ${laptop()} {
      margin-top: 15px;
      align-items: flex-start;
      flex-basis: 300px;
    }

    ${desktop()} {
      flex-basis: 350px;
    }
  `
);

export const SectionHeading = styled.h2(
  ({ theme: { laptop, desktop } }) => css`
    font-weight: 700;
    font-size: 24px;
    line-height: 1.3;
    text-align: center;
    color: #000000;

    ${laptop()} {
      font-size: 28px;
      text-align: left;
    }

    ${desktop()} {
      font-size: 32px;
    }
  `
);

export const Paragraph = styled.p`
  font-size: 15px;
  color: #596570;
  text-align: center;
  line-height: 1.5;
  margin-top: 12px;
`;

export const SectionParagraph = styled(Paragraph)(
  ({ theme: { laptop, desktop } }) => css`
    line-height: 1.6;
    margin-top: 14px;

    ${laptop()} {
      margin-top: 18px;
      font-size: 16px;
      text-align: start;
    }

    ${desktop()} {
      font-size: 18px;
    }
  `
);

export const SectionList = styled.ul(
  ({ theme: { laptop, desktop } }) => css`
    margin-top: 20px;
    margin-bottom: 20px;
    list-style: none;
    display: flex;
    flex-direction: column;
    align-items: center;

    ${laptop()} {
      margin-top: 26px;
      margin-bottom: 26px;
      align-items: flex-start;
    }

    ${desktop()} {
      margin-top: 32px;
      margin-bottom: 32px;
    }
  `
);

export const SectionListItem = styled.li(
  ({ $iconSrc, theme: { laptop, desktop } }) => css`
    display: flex;
    align-items: center;
    margin-top: 10px;
    font-weight: 500;
    font-size: 15px;
    line-height: 1.2;
    color: #000000;

    &:first-child {
      margin-top: 0;
    }

    &::before {
      content: '';
      width: 16px;
      height: 16px;
      color: #4868ff;
      background: url(${$iconSrc}) center no-repeat;
      margin-right: 8px;
    }

    ${laptop()} {
      font-weight: 700;
      font-size: 16px;
      margin-top: 15px;

      &::before {
        width: 26px;
        height: 26px;
        margin-right: 15px;
      }
    }

    ${desktop()} {
      font-size: 18px;

      &::before {
        width: 30px;
        height: 30px;
      }
    }
  `
);

export const SectionImageContainer = styled.div(
  ({ theme: { tablet, laptop } }) => css`
    width: calc(100% + var(--container-gutter-x));
    overflow: hidden;
    margin-top: 60px;

    /* hack for correct calculate resize by height */
    img[src*='data:image'] {
      width: 1px;
    }

    ${tablet()} {
      display: flex;
      justify-content: center;
    }

    ${laptop()} {
      display: block;
      margin-top: 0;
      width: auto;
    }
  `
);

export const ButtonsWrapper = styled.div(
  ({ theme: { tablet } }) => css`
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    width: 100%;
    max-width: 320px;
    gap: 10px;

    ${tablet()} {
      width: auto;
      grid-template-columns: repeat(2, 160px);
    }
  `
);
