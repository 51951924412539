import styled, { css } from 'styled-components';
import Image from '@uc-common/image';
import { SectionImageContainer, SectionWrapper } from '../../styles';

export const AccelerateUploadingSection = styled.article(
  ({ theme: { tablet, laptop, desktop } }) => css`
    margin-top: 90px;

    ${tablet()} {
      margin-top: 100px;
    }

    ${laptop()} {
      margin-top: 80px;
    }

    ${desktop()} {
      margin-top: 120px;
    }
  `
);

export const AccelerateUploadingWrapper = styled(SectionWrapper)(
  ({ theme: { tablet } }) => css`
    ${tablet()} {
      align-items: center;
    }
  `
);

export const AccelerateImageContainer = styled(SectionImageContainer)(
  ({ theme: { tablet, desktop } }) => css`
    max-width: 500px;

    ${tablet()} {
      max-width: none;
      height: 295px;
      padding: 0;
      direction: rtl;
      padding-left: var(--container-gutter-x);
    }

    ${desktop()} {
      height: 350px;
    }
  `
);

export const AccelerateUploadingImage = styled(Image)(
  ({ theme: { tablet } }) => css`
    height: auto;
    max-width: 100%;
    margin: 0 auto;

    ${tablet()} {
      height: 100%;
    }
  `
);
