import styled, { css } from 'styled-components';
import Image from '@uc-common/image';
import { SectionImageContainer, SectionWrapper } from '../../styles';

export const ExternalSourcesSection = styled.article`
  margin-top: 90px;
`;

export const ExternalSourcesWrapper = styled(SectionWrapper)(
  ({ theme: { tablet, laptop } }) => css`
    ${tablet()} {
      align-items: center;
    }

    ${laptop()} {
      flex-direction: row-reverse;
    }
  `
);

export const ExternalSourcesImageContainer = styled(SectionImageContainer)(
  ({ theme: { tablet, laptop } }) => css`
    max-width: 500px;

    ${tablet()} {
      max-width: none;
      height: 377px;
      padding: 0;
      padding-right: var(--container-gutter-x);
    }

    ${laptop()} {
      height: 420px;
    }
  `
);

export const ExternalSourcesImage = styled(Image)(
  ({ theme: { tablet } }) => css`
    height: auto;
    max-width: 100%;
    margin-left: auto;
    margin-right: auto;

    ${tablet()} {
      height: 100%;
    }
  `
);
