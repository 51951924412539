import React, { useRef } from 'react';
import useComponentSize from '@rehooks/component-size';
import { Box, Flex, Text } from 'rebass/styled-components';
import styled, { css } from 'styled-components';

import { ReactComponent as IconPlus } from '@static/icons/icon-plus.svg';
import { useSupportedFrameworks } from '../../../supported-frameworks-provider';

import { FRAMEWORKS_INTEGRATIONS } from './data';

const StyledCross = styled(IconPlus)`
  transition:
    opacity 0.3s,
    transform 0.6s;
  color: #ffffff;
  opacity: 0.3;
  width: 17px;
  height: 17px;

  ${({ $isActive }) =>
    $isActive
      ? css`
          transform: rotate(-135deg);
        `
      : css`
          transform: rotate(0deg);
        `}
`;
const StyledTitle = styled.div(
  ({ theme: { tablet, laptop } }) => css`
    transition: color 0.3s;
    font-size: 16px;
    line-height: 22px;
    font-weight: 700;
    display: flex;
    align-items: center;
    padding-top: 18px;
    padding-bottom: 18px;
    flex: 1;

    ${tablet()} {
      font-size: 18px;
    }

    ${laptop()} {
      font-size: 20px;
      line-height: 26px;
    }

    ${({ $isActive }) =>
      $isActive
        ? css`
            color: #ffffff;
            opacity: 1;
          `
        : css`
            color: #afb6c0;
          `}
  `
);

const StyledHeader = styled.div`
  cursor: pointer;

  &:hover {
    ${StyledTitle} {
      color: #ffffff;
    }

    ${StyledCross} {
      opacity: 1;
    }
  }
`;

const Item = ({ title, content, framework }) => {
  const { framework: selectedFramework, setFramework } = useSupportedFrameworks();
  const contentRef = useRef();
  const { height } = useComponentSize(contentRef);
  const isActive = framework === selectedFramework;

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        position: 'relative',
        overflow: 'hidden',
        borderTop: '1px #3b4a56 solid',
      }}
    >
      <Box
        as={StyledHeader}
        onClick={() => setFramework(isActive ? null : framework)}
        sx={{ display: 'flex', width: '100%' }}
      >
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            pl: '12px',
            py: '18px',
            mr: ['15px', '60px', '90px', '110px'],
          }}
        >
          <StyledCross $isActive={isActive} />
        </Box>
        <StyledTitle $isActive={isActive}>{title}</StyledTitle>
      </Box>
      <Box
        sx={{
          ml: ['0px', '87px', '118px', '140px'],
          transition: 'height .3s ease-in-out, opacity .3s ease-in-out',
        }}
        style={{
          height: isActive ? height : 0,
          opacity: isActive ? 1 : 0,
        }}
      >
        <Text
          ref={contentRef}
          sx={{
            fontSize: '16px',
            lineHeight: '1.8',
            maxWidth: 700,
            paddingBottom: '40px',
            color: '#BBC4D2',
          }}
        >
          {content}
        </Text>
      </Box>
    </Box>
  );
};

const Accordeon = () => (
  <Box
    id="frameworks-accordeon"
    sx={{
      width: '100%',
      borderBottom: '1px #3b4a56 solid',
    }}
  >
    {FRAMEWORKS_INTEGRATIONS.map((item) => (
      <Item key={item.title} framework={item.framework} title={item.title} content={item.content} />
    ))}
  </Box>
);

export const PackagesCodeAccordeon = () => (
  <Flex
    sx={{
      width: '100%',
      justifyContent: 'center',
      marginTop: '25px',
    }}
  >
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        width: '100%',
      }}
    >
      <Accordeon />
    </Box>
  </Flex>
);
