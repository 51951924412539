import React, { useReducer, useRef } from 'react';
import { useBlocksVersion } from '~/src/hooks/useBlocksVersion';
import { ReactComponent as ColorIcon } from './assets/icon-color.svg';
import { ReactComponent as DarkIcon } from './assets/icon-dark.svg';
import { ReactComponent as LightIcon } from './assets/icon-light.svg';
import { ReactComponent as MinimalIcon } from './assets/icon-minimal.svg';
import { ReactComponent as ModalIcon } from './assets/icon-modal.svg';
import { ReactComponent as UploaderMinimalSvg } from './assets/uploader-minimal.svg';
import { ReactComponent as UploaderRegularSvg } from './assets/uploader-regular.svg';
import {
  Backdrop,
  Background,
  ColorsWrapper,
  Container,
  RowWrapper,
  StyledCode,
  Toolbar,
  ToolbarGroup,
  UploaderSvgWrapper,
} from './BetaUploaderDemo.styles';
import { ThemeConfig, UploaderColors, UploaderMode, UploaderTheme } from './constants';
import { hex2hsl } from './hex2hsl';
import { RadioButton } from './RadioButton';
import { useMinimalModeProgressTransition } from './useMinimalModeProgressTransition';
import { useRegularModeProgressTransition } from './useRegularModeProgressTransition';
import { useShadowMatrixSync } from './useShadowMatrixSync';
import { useSnippetUpdateFlash } from './useSnippetUpdateFlash';

// `useSnippetUpdateFlash` should be updated after almost any change to this code snippet
const getCodeSnippet = ({ mode, theme, h, s, l, blocksVersion }) =>
  `
<style>
  lr-file-uploader-${mode} {
    --darkmode: ${theme === UploaderTheme.DARK ? '1' : '0'};
    --h-accent: ${Math.ceil(h)};
    --s-accent: ${Math.ceil(s)}%;
    --l-accent: ${Math.ceil(l)}%;
  }
</style>

<lr-config
  ctx-name="my-uploader"
  pubkey="YOUR_PUBLIC_KEY"
></lr-config>

<lr-file-uploader-${mode}
  ctx-name="my-uploader"
  css-src="https://cdn.jsdelivr.net/npm/@uploadcare/blocks@${blocksVersion}/web/file-uploader-${mode}.min.css">
</lr-file-uploader-${mode}>
`.trim();

const initialState = {
  mode: UploaderMode.MODAL,
  theme: UploaderTheme.DARK,
  color: UploaderColors[0],
  needInitialAnimation: false,
};

const reducer = (state, action) => {
  switch (action.type) {
    case 'setMode':
      return { ...state, mode: action.payload, needInitialAnimation: true };
    case 'setTheme':
      return { ...state, theme: action.payload, needInitialAnimation: false };
    case 'setColor':
      return { ...state, color: action.payload, needInitialAnimation: true };
    default:
      throw new Error();
  }
};

export const BetaUploaderDemo = () => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const { h, s, l } = hex2hsl(state.color);
  const blocksVersion = useBlocksVersion();
  const snippet = getCodeSnippet({
    mode: state.mode,
    theme: state.theme,
    h,
    s,
    l,
    blocksVersion,
  });

  const codeRef = useRef();
  useSnippetUpdateFlash(codeRef, snippet, state);

  const theme = {
    ...ThemeConfig[state.theme],
    '--theme-regular-accent-color': state.color,
  };
  const svgWrapperRef = useRef();
  useShadowMatrixSync(svgWrapperRef, state.theme);
  useRegularModeProgressTransition(
    svgWrapperRef,
    state.needInitialAnimation,
    state.mode,
    state.color
  );
  useMinimalModeProgressTransition(
    svgWrapperRef,
    state.needInitialAnimation,
    state.mode,
    state.color
  );

  const handleModeChange = (e) => {
    dispatch({ type: 'setMode', payload: e.target.value });
  };

  const handleThemeChange = (e) => {
    dispatch({ type: 'setTheme', payload: e.target.value });
  };

  const handleColorChange = (e) => {
    dispatch({ type: 'setColor', payload: e.target.value });
  };

  return (
    <Container>
      <Backdrop $color={`${state.color}4C`} />
      <Background />
      <RowWrapper>
        <StyledCode ref={codeRef} language="HTML" code={snippet} />
        <UploaderSvgWrapper ref={svgWrapperRef} style={theme}>
          {state.mode === UploaderMode.MODAL ? <UploaderRegularSvg /> : null}
          {state.mode === UploaderMode.MINIMAL ? <UploaderMinimalSvg /> : null}
        </UploaderSvgWrapper>
      </RowWrapper>
      <Toolbar>
        <ToolbarGroup>
          <RadioButton
            icon={<ModalIcon />}
            name="mode"
            value={UploaderMode.MODAL}
            label="Modal"
            checked={state.mode === UploaderMode.MODAL}
            onChange={handleModeChange}
          />
          <RadioButton
            icon={<MinimalIcon />}
            name="mode"
            value={UploaderMode.MINIMAL}
            label="Minimal"
            checked={state.mode === UploaderMode.MINIMAL}
            onChange={handleModeChange}
          />
        </ToolbarGroup>
        <ToolbarGroup>
          <RadioButton
            icon={<DarkIcon />}
            name="theme"
            value={UploaderTheme.DARK}
            label="Dark"
            checked={state.theme === UploaderTheme.DARK}
            onChange={handleThemeChange}
          />
          <RadioButton
            icon={<LightIcon />}
            name="theme"
            value={UploaderTheme.LIGHT}
            label="Light"
            checked={state.theme === UploaderTheme.LIGHT}
            onChange={handleThemeChange}
          />
        </ToolbarGroup>
        <ColorsWrapper>
          {UploaderColors.map((color) => (
            <RadioButton
              key={color}
              icon={<ColorIcon />}
              name="color"
              value={color}
              checked={state.color === color}
              onChange={handleColorChange}
              $color={color}
            />
          ))}
          <span>Accent color</span>
        </ColorsWrapper>
      </Toolbar>
    </Container>
  );
};
