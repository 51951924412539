import { useEffect } from 'react';
import { UploaderMode } from './constants';

export const useMinimalModeProgressTransition = (
  svgWrapperRef,
  needInitialAnimation,
  mode,
  color
) => {
  useEffect(() => {
    if (!needInitialAnimation || mode !== UploaderMode.MINIMAL) {
      return;
    }
    const timeouts = new Set();
    const svg = svgWrapperRef.current.querySelector('svg');
    const progressBar = svg.querySelector(`[class*="selector-progress-bar"]`);
    const checkIconElements = svg.querySelectorAll('[class*="selector-check-icon"');
    const originalIconTransition = window
      .getComputedStyle(checkIconElements[0])
      .getPropertyValue('transition');

    checkIconElements.forEach((el) => {
      el.style.transition = 'none';
      el.setAttribute('fill-opacity', '0');
      el.setAttribute('stroke-opacity', '0');
      const timeoutId = setTimeout(() => {
        el.style.transition = originalIconTransition;
      }, 0);
      timeouts.add(timeoutId);
    });

    const originalProgressbarTransition = window
      .getComputedStyle(progressBar)
      .getPropertyValue('transition');

    progressBar.style.transition = 'none';
    progressBar.setAttribute('width', '0');
    const timeoutId = setTimeout(() => {
      progressBar.style.transition = originalProgressbarTransition;
      progressBar.setAttribute('width', '500');
      progressBar.ontransitionend = () => {
        checkIconElements.forEach((el) => {
          el.setAttribute('fill-opacity', '1');
          el.setAttribute('stroke-opacity', '1');
        });
      };
    }, 0);
    timeouts.add(timeoutId);
    return () => {
      timeouts.forEach((id) => clearTimeout(id));
    };
  }, [needInitialAnimation, mode, color, svgWrapperRef]);
};
