import React from 'react';

import { ThemesEnum } from '@uc-common/theme';
import {
  AccelerateUploadingScreen,
  AdvantagesScreen,
  ClientsScreen,
  DevelopersScreen,
  ExternalSourcesScreen,
  ImageEditorScreen,
  MainScreen,
  StorageAndCdnScreen,
  PlatformScreenList,
} from '../../components/forFileUploader';

import { PlatformScreen } from '../../components/PlatformScreen';

import { FinalCTA } from '../../components/FinalCTA';

import { CommonLayout } from '../../components/Layout/CommonLayout';
import { Navbar } from '../../components/Navbar';
import { Footer } from '../../components/Footer';
import SupportedFrameworksProvider from '../../components/forFileUploader/supported-frameworks-provider';

const frontmatter = {
  title: 'File uploader that cares for your users',
  description:
    'With File uploader, you’ll have an end-to-end pipeline for handling user-generated content without the need to maintain a file upload and delivery infrastructure.',
};

const FileUploader = ({ location }) => {
  return (
    <CommonLayout meta={frontmatter} pathName={location.pathname} isDark>
      <Navbar initialTheme={ThemesEnum.DARK} />
      <SupportedFrameworksProvider>
        <MainScreen />
        <ClientsScreen />
        <ImageEditorScreen />
        <ExternalSourcesScreen />
        <AccelerateUploadingScreen />
        <StorageAndCdnScreen />
        <AdvantagesScreen />
        <DevelopersScreen />
      </SupportedFrameworksProvider>
      <PlatformScreen
        heading="Cover the entire file lifecycle with a single platform"
        featuresTable={false}
      >
        <PlatformScreenList />
      </PlatformScreen>
      <FinalCTA
        btnSignUpProps={{
          'data-analytics': 'btn_signup_2',
        }}
        btnScheduleDemoProps={{
          'data-analytics': 'btn_schedule-demo_2',
        }}
      />
      <Footer />
    </CommonLayout>
  );
};

export default FileUploader;
