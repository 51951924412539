import React from 'react';
import AnyIcon from '../assets/circle-any.svg';
import CustomizationIcon from '../assets/circle-customization.svg';
import DeviceIcon from '../assets/circle-device.svg';
import LightweightIcon from '../assets/circle-lightweight.svg';
import SecurityIcon from '../assets/circle-security.svg';
import WhitelabelIcon from '../assets/circle-whitelabel.svg';
import { AdvantageLink } from './styles/AdvantagesScreen.styles';

export const data = [
  {
    heading: 'Any device',
    text: 'Responsive out of the box. All controls are easily accessible on any screen.',
    icon: DeviceIcon,
  },
  {
    heading: 'Customization',
    icon: CustomizationIcon,
    renderValue: (
      <>
        Deep customization with CSS and 24 ready-made localizations and{' '}
        <AdvantageLink to="/products/file-uploader/js/" target="_blank">
          API clients
        </AdvantageLink>
      </>
    ),
  },
  {
    heading: 'Lightweight',
    text: 'No heavy dependencies, modern and efficient technologies under the hood.',
    icon: LightweightIcon,
  },
  {
    heading: 'Any size any type',
    text: 'From userpics to lossless video, the widget will transfer any format up to 5 TB.',
    icon: AnyIcon,
  },
  {
    heading: 'Whitelabeling',
    text: 'Rebrand File uploader, get custom CNAME for the uploading endpoint and CDN.',
    icon: WhitelabelIcon,
  },
  {
    heading: 'Security',
    text: 'Get full control of where uploads are coming from and who can access and handle these files.',
    icon: SecurityIcon,
  },
];
