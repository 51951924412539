import React, { useState } from 'react';
import { useBlocksVersion } from '~/src/hooks/useBlocksVersion';

import {
  IntegrationCode,
  IntegrationLinkLinkWithArrow,
  IntegrationList,
  IntegrationListItem,
  IntegrationParagraph,
  IntegrationTitle,
} from './styles/Integrations.styles';
import { IntegrationMethodToggle } from './IntegrationMethodToggle';

export const JavascriptIntegration = () => {
  const [installMethod, setInstallMethod] = useState('npm');
  const blocksVersion = useBlocksVersion();
  const IntegrationMethodToggleComponent = IntegrationMethodToggle;

  return (
    <>
      <IntegrationTitle>Install library</IntegrationTitle>
      <IntegrationMethodToggleComponent
        options={[
          {
            label: 'NPM',
            description: 'Install via the package manager',
            value: 'npm',
          },
          {
            label: 'CDN',
            description: 'Import module from CDN',
            value: 'cdn',
          },
        ]}
        value={installMethod}
        onChange={setInstallMethod}
      />
      <>
        {installMethod === 'npm' && (
          <>
            <IntegrationParagraph>Install the npm package:</IntegrationParagraph>
            <IntegrationCode
              language="bash"
              code={`npm i --save-exact @uploadcare/blocks@${blocksVersion}`}
            />
          </>
        )}

        {installMethod === 'npm' && (
          <>
            <IntegrationParagraph>
              Import, then use <code>registerBlocks</code> function to register blocks:
            </IntegrationParagraph>
            <IntegrationCode
              language="javascript"
              code={`import * as LR from "@uploadcare/blocks";

LR.registerBlocks(LR);`}
            />
          </>
        )}

        {installMethod === 'cdn' && (
          <>
            <IntegrationParagraph>
              Import ES module from CDN, then use <code>registerBlocks</code> function to register
              blocks:
            </IntegrationParagraph>
            <IntegrationCode
              language="html"
              code={`<script type="module">
  import * as LR from "https://cdn.jsdelivr.net/npm/@uploadcare/blocks@${blocksVersion}/web/lr-file-uploader-regular.min.js";

  LR.registerBlocks(LR);
</script>`}
            />
          </>
        )}
      </>
      <IntegrationTitle>Put on page</IntegrationTitle>
      Add configuration values, and place your uploader in HTML:
      <IntegrationCode
        language="html"
        code={`
<lr-config
  ctx-name="my-uploader"
  pubkey="YOUR_PUBLIC_KEY"
  img-only="true"
  multiple="true"
  max-local-file-size-bytes="524288000"
  use-cloud-image-editor="true"
  source-list="local, url, camera, dropbox"
>
</lr-config>

<lr-file-uploader-regular
  ctx-name="my-uploader"
  css-src="https://cdn.jsdelivr.net/npm/@uploadcare/blocks@${blocksVersion}/web/lr-file-uploader-regular.min.css"
>
</lr-file-uploader-regular>`}
      />
      <IntegrationParagraph>
        That’s it for the basics. See the docs for advanced configuration options:
      </IntegrationParagraph>
      <IntegrationList>
        <IntegrationListItem>All configuration parameters</IntegrationListItem>
        <IntegrationListItem>Listening to events</IntegrationListItem>
        <IntegrationListItem>Deep customization and theming</IntegrationListItem>
        <IntegrationListItem>Working with individual blocks</IntegrationListItem>
      </IntegrationList>
      <IntegrationLinkLinkWithArrow
        to="/docs/file-uploader/"
        target="_blank"
        data-analytics="btn_javascript-integration_block-documentation"
      >
        Blocks documentation
      </IntegrationLinkLinkWithArrow>
    </>
  );
};
