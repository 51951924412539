import React from 'react';
import { FrameworksEnum } from '../../../supported-frameworks-provider';
import {
  AngularIntegration,
  JavascriptIntegration,
  ReactIntegration,
  SvelteIntegration,
  VueIntegration,
} from './Integrations';

export const FRAMEWORKS_INTEGRATIONS = [
  {
    framework: `${FrameworksEnum.JAVASCRIPT}`,
    title: 'JavaScript',
    content: <JavascriptIntegration />,
  },
  {
    framework: `${FrameworksEnum.REACT}`,
    title: 'React',
    content: <ReactIntegration />,
  },
  {
    framework: `${FrameworksEnum.SVELTE}`,
    title: 'Svelte',
    content: <SvelteIntegration />,
  },
  {
    framework: `${FrameworksEnum.VUE}`,
    title: 'Vue',
    content: <VueIntegration />,
  },
  {
    framework: `${FrameworksEnum.ANGULAR}`,
    title: 'Angular',
    content: <AngularIntegration />,
  },
];
