import React from 'react';
import PropTypes from 'prop-types';

import {
  FeaturesArea,
  FeaturesBlockContainer,
  FeaturesBlockItem,
  FeaturesBlockFeatures,
  FeaturesBlockFeature,
} from '../styles/featuresBlock.styles';

import { FeaturesType } from './types';
import { CheckIcon } from '../../CommonStyles';

export const FeaturesBlock = ({ features }) => (
  <FeaturesBlockContainer>
    {features.map((feature, index) => (
      <FeaturesBlockItem key={index}>
        <FeaturesArea $background={feature.area.background}>{feature.area.title}</FeaturesArea>
        <FeaturesBlockFeatures>
          {feature.items.map((item, index) => (
            <FeaturesBlockFeature key={index}>
              <CheckIcon $background={feature.color} />
              {item}
            </FeaturesBlockFeature>
          ))}
        </FeaturesBlockFeatures>
      </FeaturesBlockItem>
    ))}
  </FeaturesBlockContainer>
);

FeaturesBlock.propTypes = {
  features: PropTypes.arrayOf(FeaturesType),
};
