export const UploaderMode = {
  MODAL: 'regular',
  MINIMAL: 'minimal',
};

export const UploaderTheme = {
  DARK: 'dark',
  LIGHT: 'light',
};

export const ThemeConfig = {
  [UploaderTheme.DARK]: {
    '--theme-regular-background': '#1B1C1D',
    '--theme-regular-text-color': '#D7D9DA',
    '--theme-regular-separator': '#252627',
    '--theme-regular-border-color': '#303132',

    '--theme-regular-inline-buttons-background': '#383A3D',
    '--theme-regular-inline-buttons-color': '#000',

    '--theme-regular-thumb-background': '#353535',

    '--theme-regular-file-corner-color-0': '#828589',
    '--theme-regular-file-corner-color-1': '#828589',
    '--theme-regular-file-body-color-0': '#787C80',
    '--theme-regular-file-body-color-1': '#656A6F',
    '--theme-regular-file-line-color': '#fff',

    '--theme-regular-button-background': '#222425',
    '--theme-regular-button-color': '#ffffff',
    '--theme-regular-button-shadow-matrix-0': '0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.3 0',
    '--theme-regular-button-shadow-matrix-1': '0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.4 0',
    '--theme-regular-button-shadow-matrix-2': '0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.2 0',

    '--theme-regular-video-background-0': '#83878A',
    '--theme-regular-video-background-1': '#5D6469',
    '--theme-regular-video-color': '#D4D6D7',

    '--theme-minimal-accent-color': 'var(--theme-regular-accent-color)',
    '--theme-minimal-border-color': '#1B1C1D',
    '--theme-minimal-file-background': '#1B1C1D',
    '--theme-minimal-file-color': 'var(--theme-regular-text-color)',
    '--theme-minimal-background': '#000',
    '--theme-minimal-drop-color': '#434648',
    '--theme-minimal-inline-buttons-background': '#ffffff',
  },
  [UploaderTheme.LIGHT]: {
    '--theme-regular-background': '#FAFAFA',
    '--theme-regular-text-color': '#252628',
    '--theme-regular-separator': '#E5E5E5',
    '--theme-regular-border-color': '#303131',

    '--theme-regular-inline-buttons-background': '#CED0D2',
    '--theme-regular-inline-buttons-color': '#FAFAFA',

    '--theme-regular-thumb-background': '#EFEFEF',

    '--theme-regular-file-corner-color-0': '#FCFCFD',
    '--theme-regular-file-corner-color-1': '#F7F7F9',
    '--theme-regular-file-body-color-0': '#fff',
    '--theme-regular-file-body-color-1': '#fff',
    '--theme-regular-file-line-color': '#BBBBC0',

    '--theme-regular-button-background': '#FAFAFA',
    '--theme-regular-button-color': '#252628',
    '--theme-regular-button-shadow-matrix-0': '0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.15 0',
    '--theme-regular-button-shadow-matrix-1': '0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0',
    '--theme-regular-button-shadow-matrix-2': '0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0',

    '--theme-regular-video-background-0': '#fff',
    '--theme-regular-video-background-1': '#fff',
    '--theme-regular-video-color': '#9D9DA6',

    '--theme-minimal-accent-color': 'var(--theme-regular-accent-color)',
    '--theme-minimal-border-color': '#E5E5E5',
    '--theme-minimal-file-background': '#F2F2F3',
    '--theme-minimal-file-color': 'var(--theme-regular-text-color)',
    '--theme-minimal-background': '#FAFAFA',
    '--theme-minimal-drop-color': '#434648',
    '--theme-minimal-inline-buttons-background': '#000000',
  },
};

export const UploaderColors = ['#B337FF', '#3771FF', '#FF378B', '#FF7F37', '#0DCA86', '#39C508'];
