import styled, { css } from 'styled-components';
import { Link } from '../../../Link';

import { Paragraph } from '../../styles';
import {
  SplitCard,
  SplitCardHeading,
  SplitCardLeftBlock,
  SplitCardParagraph,
} from '../../../SplitCard';

export const DevelopersScreenSection = styled.article(
  ({ theme: { tablet, desktop } }) => css`
    margin-top: 90px;

    ${tablet()} {
      margin-top: 100px;
    }

    ${desktop()} {
      margin-top: 120px;
    }
  `
);

export const DeveloperScreenSplitCard = styled(SplitCard)(
  ({ theme: { tablet, laptop } }) => css`
    display: grid;
    overflow-x: auto;
    background: linear-gradient(37.93deg, #0e1823 17.66%, #243341 98.18%),
      radial-gradient(82.98% 342.31% at 100% 106.77%, #ead0dd 0%, #ffe6e3 17.46%, #f6f7f8 57.92%);
    padding: 40px 15px;
    grid-gap: 46px;
    grid-template-areas:
      'content'
      'info'
      'features';

    ${tablet()} {
      padding: 50px 60px;
      grid-gap: 60px;
      grid-template-areas:
        'content content'
        'info info'
        'features .';
    }

    ${laptop()} {
      grid-template-areas:
        'content content'
        'info features';
    }
  `
);

export const DevelopersScreenContent = styled.div`
  background: none;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding: 0px;
  grid-area: content;
`;

export const DevelopersScreenInfo = styled(SplitCardLeftBlock)(
  ({ theme: { tablet } }) => css`
    background: none;
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    padding: 0px;
    grid-area: info;

    ${tablet()} {
      align-items: flex-start;
    }
  `
);

export const DevelopersScreenHeading = styled(SplitCardHeading)`
  text-align: center;
  margin-top: 20px;
`;

export const DevelopersScreenParagraph = styled(SplitCardParagraph)(
  ({ theme: { tablet, desktop } }) => css`
    text-align: center;

    ${tablet()} {
      text-align: left;
    }

    ${desktop()} {
      max-width: 616px;
    }
  `
);

export const DevelopersScreenDescription = styled(SplitCardParagraph)`
  text-align: center;
  width: 100%;
  margin-top: 10px;
`;

export const DevelopersScreenButtonWrapper = styled.div(
  ({ theme: { tablet, laptop, desktop } }) => css`
    justify-content: center;
    display: grid;
    grid-gap: 20px;
    margin-top: 10px;

    ${tablet()} {
      grid-auto-flow: column;
      grid-gap: 28px;
    }

    ${laptop()} {
      margin-top: 20px;
    }

    ${desktop()} {
      margin-top: 15px;
    }
  `
);

export const DevelopersScreenIcons = styled.div`
  display: flex;
  align-items: center;
  grid-gap: 12px;
`;

export const DevelopersScreenFeaturesBlock = styled.div(
  ({ theme: { tablet, laptop } }) => css`
    display: grid;
    grid-template-columns: repeat(1, 290px);
    grid-gap: 20px;
    margin-top: 0px;
    grid-area: features;
    justify-content: center;

    ${tablet()} {
      grid-template-columns: repeat(2, 236px);
      justify-content: space-between;
      align-items: flex-start;
    }

    ${laptop()} {
      grid-template-columns: repeat(1, 455px);
    }
  `
);

export const DevelopersScreenFeatureHeading = styled.h4(
  ({ theme: { laptop } }) => css`
    display: flex;
    flex-direction: column;
    font-weight: 500;
    font-size: 15px;
    line-height: 1.5;
    color: #ffffff;

    ${laptop()} {
      font-size: 16px;
    }
  `
);

export const DevelopersScreenFeatureBlock = styled(Link)(
  ({ theme: { tablet, laptop } }) => css`
    display: flex;
    flex-direction: column;
    align-items: center;
    text-decoration: none;
    padding: 4px 10px;
    border-radius: 6px;
    background-color: #15171900;
    transition: background-color 0.3s;

    ${tablet()} {
      align-items: flex-start;
    }

    ${laptop()} {
      align-items: flex-start;
    }

    :where(&:hover, &:focus) {
      background-color: #15171977;

      ${DevelopersScreenFeatureHeading} {
        text-decoration: underline;
      }
    }
  `
);

export const DevelopersScreenFeatureParagraph = styled(Paragraph)(
  ({ theme: { tablet, laptop } }) => css`
    color: #6e7c88;
    line-height: 150%;
    margin-top: 8px;

    ${tablet()} {
      text-align: left;
    }

    ${laptop()} {
      font-size: 16px;
      text-align: start;
    }
  `
);
