import styled, { css } from 'styled-components';

export const FeaturesBlockContainer = styled.div(
  ({ theme: { tablet, laptop } }) => css`
    margin-top: 60px;
    margin-left: calc(var(--container-gutter-x) * -1);
    width: calc(100% + var(--container-gutter-x) * 2);
    padding: 0 var(--container-gutter-x);
    overflow-x: auto;
    display: flex;

    ${tablet()} {
      margin-top: 50px;
    }

    ${laptop()} {
      padding: 0;
      margin-left: 0;
      width: 100%;
    }
  `
);

export const FeaturesArea = styled.span(
  ({ $background, theme: { laptop } }) => css`
    position: relative;
    font-weight: bold;
    font-size: 18px;
    line-height: 1.5;
    width: 241px;
    padding: 13px;
    background: ${$background || '#00b4ff'};
    border-radius: 55px;
    flex-shrink: 0;
    text-align: center;
    color: #ffffff;

    &::before {
      content: '';
      position: absolute;
      top: 0;
      bottom: 0;
      right: -19px;
      margin: auto;
      border-radius: 10px;
      width: 50px;
      height: 20px;
      background:
        center no-repeat
          url('https://ucarecdn.com/5a9ac0f0-84f7-43fc-aa83-d52d090db2f3/mini-plus.svg'),
        rgba(0, 0, 0, 0.1);
      z-index: 1;
    }

    ${laptop()} {
      width: 100%;
    }
  `
);

export const FeaturesBlockItem = styled.div(
  ({ theme: { laptop } }) => css`
    display: flex;
    flex-direction: column;
    margin-left: -11px;

    &:first-child {
      margin-left: 0;
    }

    &:last-child ${/* sc-selector */ FeaturesArea}:before {
      display: none;
    }

    ${laptop()} {
      flex-basis: calc(25% + 11px);
    }
  `
);

export const FeaturesBlockFeatures = styled.div(
  ({ theme: { laptop } }) => css`
    display: grid;
    grid-auto-columns: auto;
    grid-gap: 17px;
    margin-top: 30px;

    ${laptop()} {
      margin-top: 40px;
    }
  `
);

export const FeaturesBlockFeature = styled.span`
  display: flex;
  font-size: 15px;
  line-height: 1.2;
  color: #596570;
  max-width: 215px;
`;
