export const featuresData = [
  {
    area: {
      title: 'Upload',
      background: 'linear-gradient(90deg, #01B4FF 0%, #6A76FF 100%)',
    },
    color: '#04b2ff',
    items: [
      'Encryption',
      'Upload acceleration',
      'Error handling',
      'Multipart upload',
      'Multifile upload',
      'Attack protection',
      'Network issue handling',
      'Time-out handling',
      'Up to 5 TB files',
      'Custom validation',
      'Webhooks',
      'Signed uploads',
      'Upload API',
      'Whitelabeling',
      'Custom OAuth apps',
      'Custom CNAME',
      'Native iOS & Android',
    ],
  },
  {
    area: {
      title: 'Manage',
      background: 'linear-gradient(90deg, #6A76FF 0%, #CC51F8 100%)',
    },
    color: '#7272ff',
    items: [
      'REST API',
      'Storage',
      'Usage monitoring',
      'Webhooks',
      'Scalability',
      'Compliance',
      'Encryption',
      'Backup to S3',
      'Search',
      'Durability',
      'Metadata handling',
      'Availability',
      'Automation',
      'HIPAA compliance',
      'Custom S3 buckets',
    ],
  },
  {
    area: {
      title: 'Process',
      background: 'linear-gradient(90deg, #CC51F8 0%, #FF5EC2 100%)',
    },
    color: '#d353f6',
    items: [
      'AI-based image compression',
      'Smart cropping',
      'Auto enhancement',
      'Background removal',
      'Face detection',
      'Categorization',
      'Object tagging',
      'Color recognition',
      'Video processing',
      'GIF to video',
      'Document conversion',
      'Upscaling images',
      'Watermarks & overlays',
      'Color profiles support',
      'Color filters',
      'Malware checking',
      'Smart zoom',
      'CDN API',
    ],
  },
  {
    area: {
      title: 'Deliver',
      background: 'linear-gradient(90deg, #FF5EC2 0%, #FF7357 100%)',
    },
    color: '#ff5ebb',
    items: [
      'Content delivery network',
      'Auto responsive images',
      'Traffic optimization',
      'Custom CNAME',
      'Encryption',
      'DDoS protection',
      'Signed URLs',
      'Multiple CDN vendors',
      'Auto image format',
    ],
  },
];
