import React from 'react';

import { Button, ButtonTypeEnum } from '@uc-common/button';
import { Link } from '../../Link';
import {
  AdvantagesScreenSection,
  AdvantagesWrapper,
  AdvantageIcon,
  AdvantageBlock,
  AdvantageHeading,
  AdvantageParagraph,
  AdvantageScreenHeading,
  AdvantageScreenButtonsContainer,
} from './styles/AdvantagesScreen.styles';
import { data } from './data';

export const AdvantagesScreen = () => (
  <AdvantagesScreenSection>
    <AdvantageScreenHeading>
      Lots of file upload features in a compact package
    </AdvantageScreenHeading>
    <AdvantageScreenButtonsContainer>
      <Button
        as={Link}
        to="/accounts/signup/"
        type={ButtonTypeEnum.PRIMARY}
        data-analytics="btn_signup_1"
      >
        Start now for free
      </Button>
      <Button
        as={Link}
        to="/schedule-demo/"
        type={ButtonTypeEnum.DARK}
        data-analytics="btn_schedule-demo_1"
      >
        Get a demo
      </Button>
    </AdvantageScreenButtonsContainer>
    <AdvantagesWrapper>
      {data.map((advantage) => (
        <AdvantageBlock key={advantage.heading}>
          <AdvantageIcon $iconSrc={advantage.icon} />
          <AdvantageHeading>{advantage.heading}</AdvantageHeading>
          <AdvantageParagraph>{advantage.renderValue ?? advantage.text}</AdvantageParagraph>
        </AdvantageBlock>
      ))}
    </AdvantagesWrapper>
  </AdvantagesScreenSection>
);
