import React from 'react';
import { SectionHeading, SectionInfo, SectionParagraph } from '../styles';
import {
  StorageAndCdnImageContainer,
  StorageAndCdnSection,
  StorageAndCdnWrapper,
  StorageAndCdnImage,
} from './styles/StorageAndCdnScreen.styles';

export const StorageAndCdnScreen = () => (
  <StorageAndCdnSection>
    <StorageAndCdnWrapper>
      <SectionInfo>
        <SectionHeading>
          Integrated storage and <br />
          Content Delivery Network
        </SectionHeading>
        <SectionParagraph>
          You don’t have to worry about origin and edge relationships. All files are uploaded to
          integrated storage, ensuring they are organized and secure and automatically get cached on
          CDN.
        </SectionParagraph>
      </SectionInfo>

      <StorageAndCdnImageContainer>
        <StorageAndCdnImage
          src="https://ucarecdn.com/f341282a-14c9-4078-9543-619503fa896a/"
          alt="The Uploadcare Smart CDN is rock-solid and lightning-fast in both directions"
        />
      </StorageAndCdnImageContainer>
    </StorageAndCdnWrapper>
  </StorageAndCdnSection>
);
