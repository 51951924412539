import { useEffect, useState } from 'react';
import { hex2hsl } from './hex2hsl';
import * as st from './snippet-flash.module.css';

const getCodeNodes = (codeEl) => {
  const cssEl = codeEl.querySelector('.language-css');
  const textNodes = cssEl ? [...cssEl.childNodes].filter((node) => node.nodeType === 3) : [];
  const selectorNode = cssEl.querySelector('span:nth-child(1)');
  const themeNode = textNodes[3];
  const hNode = textNodes[5];
  const sNode = textNodes[7];
  const lNode = textNodes[9];

  const openTagNode = codeEl.querySelector('span:nth-child(6) > span.token.tag').lastChild;
  const closeTagNode = codeEl.querySelector('span:nth-child(7) > span.token.tag').lastChild;

  return {
    selectorNode,
    themeNode,
    hNode,
    sNode,
    lNode,
    openTagNode,
    closeTagNode,
  };
};

const convertTextNodesToSpans = (nodeMap) => {
  const spans = {};
  for (const [key, node] of Object.entries(nodeMap)) {
    if (node.nodeType !== 3) {
      spans[key] = node;
      continue;
    }

    const span = document.createElement('span');
    spans[key] = span;
    span.innerText = node.data.trim();

    if (node.data.startsWith(' ')) {
      node.data = ' ';
      node.after(span);
    } else {
      node.replaceWith(span);
    }
  }
  return spans;
};

const flashUpdates = (spanMap, prevState, newState) => {
  if (prevState.mode !== newState.mode) {
    spanMap.selectorNode.classList.add(st.textFlash);
    spanMap.openTagNode.classList.add(st.textFlash);
    spanMap.closeTagNode.classList.add(st.textFlash);
  }

  if (prevState.theme !== newState.theme) {
    spanMap.themeNode.classList.add(st.keyFlash);
  }

  const prevHSL = hex2hsl(prevState.color);
  const newHSL = hex2hsl(newState.color);

  if (prevHSL.h !== newHSL.h) {
    spanMap.hNode.classList.add(st.keyFlash);
  }
  if (prevHSL.s !== newHSL.s) {
    spanMap.sNode.classList.add(st.keyFlash);
  }
  if (prevHSL.l !== newHSL.l) {
    spanMap.lNode.classList.add(st.keyFlash);
  }
};

export const useSnippetUpdateFlash = (codeRef, snippet, state) => {
  const [prevState, setPrevState] = useState(state);
  useEffect(() => {
    const codeEl = codeRef.current;
    if (!codeEl) {
      setPrevState(state);
      return;
    }
    const nodeMap = getCodeNodes(codeEl);
    const spanMap = convertTextNodesToSpans(nodeMap);
    flashUpdates(spanMap, prevState, state);

    setPrevState(state);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [codeRef, snippet]);
};
