import React from 'react';

import {
  PlatformScreenListWrapper,
  PlatformScreenStyledList,
  PlatformScreenListLink,
} from './styles/PlatformScreenList.styles';

export const PlatformScreenList = () => (
  <PlatformScreenListWrapper>
    Leverage from using the whole Uploadcare platform:
    <PlatformScreenStyledList>
      <li>Easily upload files from your web application using API</li>
      <li>Sleek HTML file upload widget</li>
      <li>Support public and private file</li>
      <li>Lightning-fast content delivery via Uploadcare’s multi-vendor CDN</li>
      <li>Real-time image processing via URL API</li>
      <li>
        <PlatformScreenListLink to="/cdn/image-processing/">
          40+image transformations
        </PlatformScreenListLink>
        : resize, crop, rotate, remove background, apply watermarks and much more
      </li>
    </PlatformScreenStyledList>
  </PlatformScreenListWrapper>
);
