import React from 'react';

import {
  PlatformScreenSection,
  PlatformScreenWrapper,
  PlatformScreenHeading,
  PlatformScreenSuperHeading,
  PlatformScreenSubtext,
} from './styles/platformScreen.styles';

import { featuresData } from './data';
import { FeaturesBlock } from './components/featuresBlock';

export const PlatformScreen = ({
  superHeading = 'Unified platform',
  heading,
  text,
  featuresTable = true,
  children,
}) => (
  <PlatformScreenSection>
    <PlatformScreenWrapper>
      <PlatformScreenSuperHeading>{superHeading}</PlatformScreenSuperHeading>
      {heading && <PlatformScreenHeading>{heading}</PlatformScreenHeading>}
      {text && <PlatformScreenSubtext>{text}</PlatformScreenSubtext>}
      {Boolean(featuresTable) && <FeaturesBlock features={featuresData} />}
      {children}
    </PlatformScreenWrapper>
  </PlatformScreenSection>
);
