import styled, { css } from 'styled-components';
import Image from '@uc-common/image';
import { SectionImageContainer, SectionWrapper } from '../../styles';

export const ImageEditorSection = styled.article(
  ({ theme: { tablet, laptop, desktop } }) => css`
    margin-top: 90px;

    ${tablet()} {
      margin-top: 100px;
    }

    ${laptop()} {
      margin-top: 50px;
    }

    ${desktop()} {
      margin-top: 60px;
    }
  `
);

export const ImageEditorWrapper = styled(SectionWrapper)(
  ({ theme: { tablet } }) => css`
    ${tablet()} {
      align-items: center;
    }
  `
);

export const ImageEditorImageContainer = styled(SectionImageContainer)(
  ({ theme: { tablet, laptop } }) => css`
    margin-top: 20px;
    max-width: 500px;

    ${tablet()} {
      max-width: none;
      margin-top: 15px;
      padding: 0;
      height: 377px;
      direction: rtl;
      padding-left: var(--container-gutter-x);
    }

    ${laptop()} {
      margin-top: 0;
      height: 460px;
    }
  `
);

export const ImageEditorOperations = styled.div(
  ({ theme: { tablet, laptop } }) => css`
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    margin-top: 20px;
    margin-bottom: 28px;
    width: 100%;

    ${tablet()} {
      max-width: 420px;
    }

    ${laptop()} {
      width: 100%;
      justify-content: flex-start;
    }
  `
);

export const ImageEditorOperation = styled.span`
  font-weight: 700;
  font-size: 12px;
  line-height: 1.66;
  letter-spacing: 0.035em;
  text-transform: uppercase;
  border-radius: 30px;
  padding: 6px 14px;
  background: #f6f7f8;
  margin: 7px 3px 0 0;
`;

export const ImageEditorImage = styled(Image)(
  ({ theme: { tablet } }) => css`
    height: auto;
    max-width: 100%;
    margin-left: auto;
    margin-right: auto;

    ${tablet()} {
      height: 100%;
    }
  `
);
