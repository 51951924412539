import React from 'react';
import { useBlocksVersion } from '~/src/hooks/useBlocksVersion';

import {
  IntegrationCode,
  IntegrationLinkLinkWithArrow,
  IntegrationList,
  IntegrationListItem,
  IntegrationParagraph,
  IntegrationTitle,
  IntegrationLink,
} from './styles/Integrations.styles';

export const AngularIntegration = () => {
  const blocksVersion = useBlocksVersion();

  return (
    <>
      <IntegrationTitle>Install library</IntegrationTitle>
      <IntegrationParagraph>Install the npm package:</IntegrationParagraph>
      <IntegrationCode
        language="bash"
        code={`npm i --save-exact @uploadcare/blocks@${blocksVersion}`}
      />
      <IntegrationParagraph>
        Import, then use <code>registerBlocks</code> function to register blocks:
      </IntegrationParagraph>
      <IntegrationCode
        language="javascript"
        code={`import * as LR from "@uploadcare/blocks";

LR.registerBlocks(LR);`}
      />
      <IntegrationParagraph>
        To use Blocks, which are built on custom elements, you must inform Angular of their usage.
        This is achieved by setting the schema property of <code>@NgModule</code> to{' '}
        <code>CUSTOM_ELEMENTS_SCHEMA</code> like this:
      </IntegrationParagraph>
      <IntegrationCode
        language="javascript"
        code={`import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { UploaderComponent } from './uploader.component';

@NgModule({
  declarations: [UploaderComponent],
  imports: [BrowserModule],
  providers: [],
  bootstrap: [UploaderComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class UploaderModule {}`}
      />
      <IntegrationParagraph>
        For further information on schemas, please refer to the{' '}
        <IntegrationLink
          target="_blank"
          rel="noopener noreferrer"
          to="https://angular.io/api/core/NgModule#schemas"
        >
          Angular documentation.
        </IntegrationLink>
      </IntegrationParagraph>
      <IntegrationTitle>Put on page</IntegrationTitle>
      Add configuration values, and place your uploader in HTML:
      <IntegrationCode
        language="html"
        code={`
<lr-config
  ctx-name="my-uploader"
  pubkey="YOUR_PUBLIC_KEY"
  img-only="true"
  multiple="true"
  max-local-file-size-bytes="524288000"
  use-cloud-image-editor="true"
  source-list="local, url, camera, dropbox"
>
</lr-config>

<lr-file-uploader-regular
  ctx-name="my-uploader"
  css-src="https://cdn.jsdelivr.net/npm/@uploadcare/blocks@${blocksVersion}/web/lr-file-uploader-regular.min.css"
>
</lr-file-uploader-regular>`}
      />
      <IntegrationParagraph>
        That’s it for the basics. See the docs for advanced configuration options:
      </IntegrationParagraph>
      <IntegrationList>
        <IntegrationListItem>All configuration parameters</IntegrationListItem>
        <IntegrationListItem>Listening to events</IntegrationListItem>
        <IntegrationListItem>Deep customization and theming</IntegrationListItem>
        <IntegrationListItem>Working with individual blocks</IntegrationListItem>
      </IntegrationList>
      <IntegrationLinkLinkWithArrow
        to="/docs/file-uploader/"
        target="_blank"
        data-analytics="btn_angular-integration_block-documentation"
      >
        Blocks documentation
      </IntegrationLinkLinkWithArrow>
      <IntegrationLinkLinkWithArrow
        to="https://codesandbox.io/s/github/uploadcare/blocks-examples/tree/main/examples/angular-uploader/"
        target="_blank"
        rel="noopener noreferrer"
        data-analytics="btn_angular-integration_code-sandbox-example"
      >
        CodeSandbox example
      </IntegrationLinkLinkWithArrow>
    </>
  );
};
