import React, { createContext, useContext, useState } from 'react';

export const FrameworksEnum = {
  JAVASCRIPT: 'javascript',
  REACT: 'react',
  SVELTE: 'svelte',
  VUE: 'vue',
  ANGULAR: 'angular',
};

export const SupportedFrameworksContext = createContext();

export const useSupportedFrameworks = () => {
  return useContext(SupportedFrameworksContext);
};

const SupportedFrameworksProvider = ({ children }) => {
  const [framework, setFramework] = useState(FrameworksEnum.JAVASCRIPT);

  return (
    // eslint-disable-next-line react/jsx-no-constructed-context-values
    <SupportedFrameworksContext.Provider value={{ framework, setFramework }}>
      {children}
    </SupportedFrameworksContext.Provider>
  );
};

export default SupportedFrameworksProvider;
