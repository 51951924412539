import styled, { css } from 'styled-components';
import { Link } from '~/src/components/Link';

export const PlatformScreenStyledList = styled.ul(
  ({ theme: { tablet, laptop } }) => css`
    display: grid;
    grid-gap: 5px;
    margin-left: 20px;

    ${tablet()} {
      grid-gap: 5px;
    }

    ${laptop()} {
      grid-gap: 10px;
    }
  `
);

export const PlatformScreenListWrapper = styled.div(
  ({ theme: { tablet, laptop } }) => css`
    width: 100%;
    color: #9ba5ad;
    line-height: 1.6;
    font-size: 16px;
    margin-top: 25px;
    display: grid;
    grid-gap: 10px;

    ${tablet()} {
      font-size: 18px;
      grid-gap: 15px;
    }

    ${laptop()} {
      font-size: 20px;
      grid-gap: 20px;
    }
  `
);

export const PlatformScreenListLink = styled(Link)`
  color: #9ba5ad;
`;
