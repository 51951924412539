import { useEffect } from 'react';
import { ThemeConfig } from './constants';

export const useShadowMatrixSync = (svgWrapperRef, theme) => {
  // I wonder if it's possible to bind feColorMatrix's `values` attribute onto the css custom property
  // Didn't find that way
  // So I decided to set the `values` attr value manually
  useEffect(() => {
    const svg = svgWrapperRef.current.querySelector('svg');
    for (const i of [0, 1, 2]) {
      // Our svgr/svgo setup strips any custom data-* attrs from svg
      // and alters `class` attr value so we need to use *= contains selector
      const feColorMatrixElements = svg.querySelectorAll(
        `[class*="selector-regular-button-shadow-matrix-${i}"]`
      );
      for (const el of feColorMatrixElements) {
        const themeConfig = ThemeConfig[theme];
        const values = themeConfig[`--theme-regular-button-shadow-matrix-${i}`];
        el.setAttribute('values', values);
      }
    }
  }, [svgWrapperRef, theme]);
};
