import React from 'react';

import { Link } from '~/src/components/Link';
import { SupportedFrameworksItem } from '../../styles/MainScreen.styles';

export const SupportedFramework = ({ icon, onClick, selected }) => (
  <SupportedFrameworksItem
    $isSelected={selected}
    onClick={onClick}
    as={Link}
    offset={-290}
    to="#frameworks-accordeon"
  >
    {Boolean(icon) && icon}
  </SupportedFrameworksItem>
);
