import PropTypes from 'prop-types';

export const AreaType = PropTypes.shape({
  title: PropTypes.string,
  background: PropTypes.string,
});

export const FeaturesType = PropTypes.shape({
  area: AreaType,
  color: PropTypes.string,
  items: PropTypes.arrayOf(PropTypes.string),
});
