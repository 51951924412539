import React from 'react';

import AccelerationIcon from '../assets/circle-acceleration.svg';
import MultipartIcon from '../assets/circle-multipart.svg';
import {
  SectionHeading,
  SectionInfo,
  SectionList,
  SectionListItem,
  SectionParagraph,
} from '../styles';
import {
  AccelerateImageContainer,
  AccelerateUploadingSection,
  AccelerateUploadingWrapper,
  AccelerateUploadingImage,
} from './styles/AccelerateUploadingScreen.styles';

export const AccelerateUploadingScreen = () => (
  <AccelerateUploadingSection>
    <AccelerateUploadingWrapper>
      <SectionInfo>
        <SectionHeading>Accelerated file uploading</SectionHeading>
        <SectionParagraph>
          Uploadcare widget is backed by global high performance infrastructure
        </SectionParagraph>
        <SectionList>
          <SectionListItem $iconSrc={AccelerationIcon}>
            Network of acceleration nodes
          </SectionListItem>
          <SectionListItem $iconSrc={MultipartIcon}>Multipart uploading</SectionListItem>
        </SectionList>
      </SectionInfo>
      <AccelerateImageContainer>
        <AccelerateUploadingImage
          src="https://ucarecdn.com/840bf34f-3733-4c46-bfca-c0872d5ffebf/accelerated.svg"
          alt="Widget speed comparison"
        />
      </AccelerateImageContainer>
    </AccelerateUploadingWrapper>
  </AccelerateUploadingSection>
);
