import React, { Fragment, useCallback } from 'react';
import {
  IntegrationMethodToggleContainer,
  IntegrationMethodToggleInput,
  IntegrationMethodToggleLabel,
} from './IntegrationMethodToggle.styles';

export const IntegrationMethodToggle = ({ value, onChange, options }) => {
  const handleChange = useCallback(
    (e) => {
      const target = e.currentTarget;
      onChange(target.value);
    },
    [onChange]
  );

  return (
    <IntegrationMethodToggleContainer>
      {options.map((option) => {
        const inputId = `${option.value}`;
        return (
          <Fragment key={option.label}>
            <IntegrationMethodToggleInput
              type="radio"
              id={inputId}
              value={option.value}
              onChange={handleChange}
              checked={option.value === value}
            />
            <IntegrationMethodToggleLabel htmlFor={inputId}>
              <span>{option.label}</span>
              <span>{option.description}</span>
            </IntegrationMethodToggleLabel>
          </Fragment>
        );
      })}
    </IntegrationMethodToggleContainer>
  );
};
