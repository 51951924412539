import { useEffect } from 'react';
import { UploaderMode } from './constants';

export const useRegularModeProgressTransition = (
  svgWrapperRef,
  needInitialAnimation,
  mode,
  color
) => {
  useEffect(() => {
    if (!needInitialAnimation || mode !== UploaderMode.MODAL) {
      return;
    }
    const svg = svgWrapperRef.current.querySelector('svg');
    const progressBar = svg.querySelector(`[class*="selector-progress-bar"]`);
    const originalTransition = window.getComputedStyle(progressBar).getPropertyValue('transition');

    let timeoutId;
    progressBar.style.transform = `translateX(100%)`;
    progressBar.ontransitionend = () => {
      clearTimeout(timeoutId);
      progressBar.style.transition = 'none';
      progressBar.style.transform = `translateX(-100%)`;
      timeoutId = setTimeout(() => {
        progressBar.style.transition = originalTransition;
        progressBar.style.transform = `translateX(0%)`;
        progressBar.ontransitionend = undefined;
      }, 1);
    };
    return () => {
      clearTimeout(timeoutId);
    };
  }, [needInitialAnimation, mode, color, svgWrapperRef]);
};
