import styled, { css } from 'styled-components';
import { Container } from '@uc-common/container';
import { SectionHeading, SectionSubtext, GradientSuperHeading } from '../../Typography';

export const PlatformScreenSection = styled.article(
  ({ theme: { desktop, laptop } }) => css`
    margin: 100px 0;

    ${laptop()} {
      margin: 120px 0;
    }

    ${desktop()} {
      margin: 140px 0;
    }
  `
);

export const PlatformScreenWrapper = styled(Container)(
  ({ theme: { laptop } }) => css`
    display: flex;
    flex-direction: column;

    ${laptop()} {
      align-items: center;
    }
  `
);

export const PlatformScreenHeading = styled(SectionHeading)(
  ({ theme: { laptop } }) => css`
    ${laptop()} {
      max-width: 840px;
    }
  `
);

export const PlatformScreenSuperHeading = styled(GradientSuperHeading)(
  ({ theme: { laptop } }) => css`
    ${laptop()} {
      margin-bottom: 30px;
    }
  `
);

export const PlatformScreenSubtext = styled(SectionSubtext)(
  ({ theme: { laptop } }) => css`
    ${laptop()} {
      max-width: 840px;
    }
  `
);
